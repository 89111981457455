<template>
    <router-view/>
</template>
<script>
export default {
  data(){
      return{}
  },
  created() {    
  },
  mounted(){
      window.addEventListener('resize',function(){
          location.reload();
      })      
  }
}
</script>
<style>
/* *{
  font-size: 16px !important;
} */


</style>