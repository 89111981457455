import { createApp } from 'vue'
import router from './router/index'

import App from './App.vue'
import ViewUIPlus from 'view-ui-plus'
import * as echarts from 'echarts'
import '../src/assets/css/index.css'
import 'view-ui-plus/dist/styles/viewuiplus.css'

// document.addEventListener('keydown', function(e) {
//     if (e.key === 'F12' || e.keyCode === 123) {
//         e.preventDefault();
//     }
// }); 

const app = createApp(App)
// 全局挂载 echarts
app.config.globalProperties.$echarts = echarts;
app.use(router).use(ViewUIPlus).mount('#app')
