export function filterAsyncRoutes(routes, roles) {   
    const res = [];
    routes.forEach(route => {
        const tmp = { ...route };
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles);
            }
            res.push(tmp);
        }
    });  
    return res;
}
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        return route.meta.roles.includes(roles);
    }
}